
.bodyContainer{
    width:calc(100% - 270px);
    transition: .2s all;
}
.toggled + .bodyContainer{
    width:calc(100% - 80px)
}
.bg-primary.accounts-links {
    background-color: #007d96 !important;
}
.btn-primary {
    background-color: #007d96 !important;
    border-color: #007d96 !important;
}
.btn-primary:hover {
    background-color: #0bb2d4;
    border-color: #0bb2d4;
}
.MuiAppBar-colorPrimary {
    background-color: #007d96 !important;
}
.bg-secondary {
    background-color: #424242 !important;
}
.btn-secondary{
    background-color: #424242;
    border-color: #424242;
}
.btn-secondary:hover {
    background-color: #757575;
    border-color: #757575;
}
.MuiTableBody-root a{
    color: #007d96;
    font-weight:600;
    text-decoration: none;
}
.MuiTableBody-root a:hover{
    text-decoration: underline;
}

/*Sidebar*/
.lalqgO .head-div .icon-suffix {
    margin: 0 !important;
}
.accounts-links .nav-link{
    padding: 24px 1rem;
}
.sidebar_pageTitle{
    padding-top:0 !important;
    padding-bottom:0 !important;
    color: #0099b8;
}
.sidebar_pageTitle:hover{
    color: #92f0e6;
}