html {
  font-family: "Open Sans", sans-serif;
  font-size: 55%;
}
body {
  font-family: "Open Sans", sans-serif;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #6b6b6b;
}
h1 {
  font-family: "EB Garamond", serif;
  font-size: 5.4rem;
  line-height: 5.4rem;
  font-weight: 700;
}
h2 {
  font-family: "EB Garamond", serif;
  font-size: 3.5rem;
  line-height: 3.4rem;
  font-weight: 600;
  line-height: 3rem;
  padding: 1.6rem 0;
}
h3 {
  font-size: 2.2rem;
  line-height: 2.2rem;
  text-transform: uppercase;
  margin-bottom: 0;
  letter-spacing: 0.1rem;
}
h4 {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
}
h5 {
  font-size: 1.7rem;
  line-height: 2rem;
  font-weight: bold;
}
.text-primary {
  color: #0083c9 !important;
}
.text-secondary {
  color: #fba919 !important;
}
.text-dark {
  color: #6b6b6b !important;
}
.bg-primary {
  background-color: #0083c9;
}
.bg-secondary {
  background-color: #fba919;
}
.bg-dark {
  background-color: #6b6b6b;
}
.btn {
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0.1rem;
}
.btn-primary {
  background-color: #0083c9;
  border-color: #0083c9;
}
.btn-secondary {
  background-color: #fba919;
  border-color: #fba919;
  font-weight: 700;
}
.btn-dark {
  background-color: #6b6b6b;
  border-color: #6b6b6b;
}
.readMore {
  color: #6b6b6b;
}
.adminInterface {
  font-size: 1.7rem;
}
.section-img {
  width: 100%;
  position: relative;
}
.section-img > img {
  max-width: 100%;
  padding: 10px;
}
.section-img:before {
  content: "";
  display: block;
  width: 33%;
  height: 25%;
  position: absolute;
  top: 0;
  left: 0;
  border-top: 10px solid #fba919;
  border-left: 10px solid #fba919;
}
.section-img:after {
  content: "";
  display: block;
  width: 33%;
  height: 25%;
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom: 10px solid #0083c9;
  border-right: 10px solid #0083c9;
}
.whyus-icon {
  height: 100px;
  text-align: center;
}
.top-menu {
  box-shadow: 5px 5px 35px rgba(0, 0, 0, 0.1);
}
.nav-link.current {
  color: #0083c9 !important;
  text-decoration: underline !important;
}
.nav-link.active {
  color: #212529;
  text-decoration: none;
}
footer {
  background: #074464;
  color: #fff;
  padding: 3rem 0;
}
.footerMenu li {
  padding: 0 5px;
  list-style: none;
}
.footerMenu a {
  color: #fff;
}
.footerColHeader {
  height: 100px;
  border-bottom: 1px solid #0083c9;
  padding-bottom: 1.6rem;
  margin-bottom: 1.6rem;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
}
.lastDiv {
  color: #fff;
  background: #0083c9;
  font-size: 1.2rem;
  line-height: 2.5rem;
  text-align: center;
}
.signinImg {
  height: 100vh;
  background-size: cover;
}
#scopeAndSequence {
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(2, 15vw);
  grid-gap: 0.5rem;
}
.ss_sub {
  display: grid;
  grid-template-columns: 2fr;
  grid-template-rows: 2fr;
}
.ss_sub .ss_sub-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
}
.ss_sub .ss_sub-h5 {
  text-transform: uppercase;
  color: #fff;
  background: #0083c9;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  align-self: end;
  justify-self: center;
  padding: 0.5rem 2rem;
  margin: 0;
  transition: 0.3s all;
  letter-spacing: 0.1rem;
  font-weight: 600;
}
.ss_sub-a {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  background: #000a;
  text-align: center;
  display: flex;
  opacity: 0;
  transition: 0.3s all;
}
.ss_sub-a:hover {
  text-decoration: none;
}
.ss_sub-a-p {
  color: #fff;
  align-self: center;
  font-size: 1.5rem;
  padding: 30px;
}
.ss_sub-a-p h5 {
  text-transform: uppercase;
}
.ss_sub:hover .ss_sub-h5 {
  opacity: 0;
}
.ss_sub:hover .ss_sub-a {
  opacity: 1;
}
.ss_coding {
  grid-column: 1 / span 2;
  grid-row: 1 / 2;
}
.ss_robotics {
  grid-column: 3 / span 3;
  grid-row: 1 / 3;
}
.ss_engineering {
  grid-column: 6 / span 2;
  grid-row: 1 / 2;
}
.ss_microcontrol {
  grid-column: 1 / span 2;
  grid-row: 2 / 3;
}
.ss_drawing {
  grid-column: 6 / span 2;
  grid-row: 2 / 3;
}
.carousel-caption {
  width: 100%;
  /* right: 0; */
  left: 0;
  position: relative;
}
.socialMediaCont {
  position: absolute;
  left: -6rem;
  top: 50vh;
  transform: translateY(-50%);
  z-index: 1000;
}
.footer_socialMediaCont a {
  color: #fff;
  padding-right: 1.5rem;
  font-size: 2rem;
}
.footer_socialMediaCont a:hover {
  color: #fba919;
}
.socialMediaCont a {
  display: block;
  color: #d5d5d5;
  margin: 3rem;
  font-size: 2rem;
  position: relative;
}
.socialMediaCont a:after {
  content: "";
  width: 1px;
  background: #d5d5d5;
  height: 20px;
  position: absolute;
  left: 50%;
  bottom: -25px;
}
.socialMediaCont a:last-child:after {
  display: none;
}
.socialMediaCont a:hover {
  color: #fba919;
}
input,
.MuiFormLabel-root,
.MuiChip-root,
.MuiTypography-body1,
.btn,
.form-control,
.form-select {
  font-size: 1.3rem !important;
}
.MuiTypography-h6 {
  font-size: 1.6rem !important;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  transform: scale(0.85) translateY(-0.8rem) translateX(0.15rem);
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(4rem + 2px);
}
.sliderImg {
  width: 100%;
}
.newsletterCont {
  position: relative;
}
.newsletterCont > div > div {
  position: absolute;
  left: 0;
  bottom: -20px;
  font-size: 1.3rem;
  width: 120%;
  color: #fff !important;
  font-weight: 600;
}
.newsletterCont > div {
  background: #fff;
  position: relative;
}
.newsletterCont > div > input {
  width: calc(100% - 40px);
  background: none;
  border: 0;
  padding: 0.5rem 1rem;
}
.newsletterCont > div > button {
  width: 40px;
  color: #0000;
  background: none;
  border: 0;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.newsletterCont > div > button:before {
  content: "\f124";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  color: #000f;
}
.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
}
.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}
.modal-confirm .icon-box {
  color: #fff;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -70px;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  z-index: 9;
  background: #fba919;
  padding: 15px;
  text-align: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}
.modal-confirm .icon-box i {
  font-size: 58px;
  position: relative;
  top: 3px;
}
.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -15px;
}
.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
}
.modal-confirm .btn {
  border-radius: 0.5rem;
}
.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 1rem;
  z-index: 1000;
  box-shadow: 5px 5px 35px rgba(0, 0, 0, 0.2);
}
.carousel-indicators li {
  background-color: #fba919;
}
.carousel-control-prev,
.carousel-control-next {
  z-index: 1000;
}
.topSliderContainer:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 1000;
}
.adminInterface .menuLink {
  padding: 15px;
  cursor: pointer;
  border-bottom: 1px solid #fff9;
}
.adminInterface .subMenuLink {
  overflow: hidden;
}
.adminInterface .subMenuLink > div {
  position: relative;
  margin-top: -150%;
  transition: 1s all;
}
#testimonials .testimonials_quotes {
  width: 4rem;
  position: absolute;
  top: 0;
  left: -5rem;
}
#testimonials .carousel-indicators {
  justify-content: left;
  margin-left: 0;
}
.section-img {
  max-width: 80%;
  height: 100%;
}
#testimonials .carousel-inner {
  padding-bottom: 15rem;
}
.btnWithIcons {
  border: 0;
  background: none;
  padding-left: 7px;
}
.navbar-light .navbar-nav .nav-link {
  font-size: 1.4rem;
}
.pb-3.navbar-nav {
  font-size: 1.4rem;
}
@media only screen and (min-width: 576px) {
  .carousel-caption {
    width: 540px;
    /* right: calc((100vw - 540px)/2);
        left: calc((100vw - 540px)/2); */
    top: auto !important;
  }
  .top-menu {
    top: 0 !important;
  }
  .section-img {
    width: 80%;
    max-height: 100vh;
  }
  .topSliderContainer:before {
    width: calc((100vw - 540px) / 2);
  }
  .w-sm-50 {
    width: 50%;
  }
  #home .carousel-inner {
    height: 100vh;
  }
  #home .sliderContentText {
    padding-left: 100px;
  }
}
@media only screen and (min-width: 768px) {
  .top-menu {
    top: 4% !important;
  }
  .carousel-caption {
    width: 720px;
    /* right: calc((100vw - 720px)/2);
        left: calc((100vw - 720px)/2); */
    /* padding-left:85px; */
    position: absolute;
  }
  .socialMediaCont {
    left: 0;
  }
  .sliderImg {
    width: 50%;
  }
  .testimonials-text {
    width: 50%;
  }
  .footerMenu li {
    padding: 0 20px;
  }
  .topSliderContainer:before {
    width: calc((100vw - 720px) / 2);
  }
}
@media only screen and (min-width: 992px) {
  html {
    font-size: 62.5%;
  }
  .carousel-caption {
    width: 960px;
    /* right: calc((100vw - 960px)/2);
        left: calc((100vw - 960px)/2); */
    top: 0 !important;
  }
  .topSliderContainer:before {
    width: calc((100vw - 960px) / 2);
  }
}
@media only screen and (min-width: 1200px) {
  .carousel-caption {
    width: 1140px;
    /* right: calc((100vw - 1140px)/2);
        left: calc((100vw - 1140px)/2); */
  }
  .topSliderContainer:before {
    width: calc((100vw - 1140px) / 2);
  }
}

.quiz-img:hover {
  width: 640px !important;
  height: 360px !important;
}

.question-img {
  width: 640px !important;
  height: 360px !important;
}
